import { makeSelectors } from "libs/testing/makeSelectors";

export const DOMAIN_NAME = "content-rules";
export const CREATE_NEW_ID = "new_rule_id";

export const MAX_DLP_NAME_LENGTH = 128;

export const dlpSelectors = makeSelectors("dlp", {
  customPolicyForm: "",
  customPolicyFormCancel: "",
  customPolicyFormDelete: "",
  customPolicyFormSave: "",
  customPolicyFormToggleActive: "",
  policyAdvancedModeToggle: "",
  ruleAdvancedModeToggle: "",
  customPolicyFormRuleSelector: (ruleId: string) => ruleId,
  customPolicyFormRuleOccurrences: (ruleId: string) => ruleId,
  expandPredefinedPolicies: "",
  expandCustomPolicies: "",
  policyRowDelete: "",
  policySelectionToggle: "",
  confirmPoliciesSelection: "",
  ruleName: "",
  ruleErrorMessage: "",
  ruleDescription: "",
  ruleXml: "",
  policyName: "",
  policyDescription: "",
  showSelectedOnly: "",
  policy: "",
  customRuleForm: "",
  customRuleFormCancel: "",
  customRuleFormDelete: "",
  customRuleFormSave: "",
  customRuleFormToggleActive: "",
  rule: "",
});

export const EMPTY_DATETIME = "0001-01-01T00:00:00Z";

export const TagsKey = "tags-key";
export const CustomPoliciesKey = "nucleuz-custom-policies-key";
export const PredefinedPoliciesKey = "nucleuz-predefined-policies-key";
export const SelectedPolicies = "nucleuz-selected-policies";
export const CustomRulesKey = "nucleuz-rules-key";
export const PredefinedRulesKey = "nucleuz-predefined-rules-key";

export const RULE_XML_TEMPLATE = `
<Rules>
  <!-- Entity is required and must contain at least one Pattern child -->
  <!-- patternsProximity 1+ (in number of characters) that the Entity's child/inner Pattern elements will consider surrounding content as corroborative evidence. -->
  <!-- recommendedConfidence 1 - 100 confidence threshold to consider the Entity as matching. -->
  <Entity patternsProximity="100" recommendedConfidence="85">
     <!-- confidenceLevel 1 - 100 The confidence that this Pattern contributes to the parent Entity's overall confidence level if it matches. -->
    <Pattern confidenceLevel="85">
      <!-- idRef is required and should reference Keyword id or Regex id  -->
      <IdMatch idRef="num_string" />
      <!-- The match won't be included in the match results; instead, it serves as supplementary evidence  -->
      <Match idRef="words" minOccurs="1" />
    </Pattern>
  </Entity>

  <!-- The 'id' should be unique per rule; the IDs of both Keywords and Regex should not repeat. -->
  <Keyword id="words">
    <Group matchStyle="word">
      <!-- match either of these strings -->
      <Term caseSensitive="true">google</Term>
      <Term caseSensitive="false">facebook</Term>
    </Group>
  </Keyword>

  <!--  Perl regular expression syntax  -->
  <Regex id="phone_number">\d{10}</Regex>

  <Keyword id="num_string">
    <Group matchStyle="numerical_string">
      <Term caseSensitive="false">9999.9999</Term>
    </Group>
  </Keyword>
</Rules>
`;
